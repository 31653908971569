import React, { useState, useCallback } from "react";
import { client } from "./client-contentful";

// function to clean contentful data
const cleanContentfulDataProducts = (data) => {
  return data.map((item) => {
    const id = item.sys.id;
    const {
      image: {
        fields: {
          file: { url},
        },
      },
      description,
      title,
      category,
    } = item.fields;
    let filtered = { id };

    if (url) {
      filtered = { ...filtered, url };
    }
    if (title) {
      filtered = { ...filtered, title };
    }
    if (category) {
      filtered = { ...filtered, category };
    }
    if (description) {
      filtered = { ...filtered, desc: description };
    }
    return filtered;
  });
};

// function to be called in each contentful data component
export const ContentfulDataProducts = (subject) => {
  const [contentful, setContentful] = useState([]);
  const getCarouselSlides = useCallback(async () => {
    try {
      const response = await client.getEntries({
        content_type: subject,
      });
      const heroImages = response?.items;
      const carouselfields = cleanContentfulDataProducts(heroImages);
      setContentful(carouselfields);
    } catch (error) {
      console.log(error);
    }
  }, [subject]);

  React.useEffect(() => {
    getCarouselSlides();
  }, [getCarouselSlides]);
  return contentful;
};
