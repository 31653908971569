import React, { useEffect, useState } from "react";
import { Formik, Form, useField } from "formik";
import * as Yup from "yup";
import { GiCheckMark } from "react-icons/gi";
import { appendSpreadsheet } from "./appendToGoogleSheet";

import styled from "@emotion/styled";
import "./Service.css";

const MyTextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <label htmlFor={props.id || props.name}>{label}</label>
      <input className="text-input" {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="formik-error">{meta.error}</div>
      ) : null}
    </>
  );
};
const MyTextArea = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <label htmlFor={props.id || props.name}>{label}</label>
      <textarea className="text-input" {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="formik-error">{meta.error}</div>
      ) : null}
    </>
  );
};

// Styled components ....
const StyledSelect = styled.select`
  color: var(--blue);
`;

const StyledErrorMessage = styled.div`
  font-size: 12px;
  color: var(--red-600);
  width: 400px;
  height: 7px;
  margin-top: 0.25rem;
  &:before {
    content: "❌ ";
    font-size: 10px;
    max-width: 400px;
    max-height: 7px;
  }
  @media (max-width: 768px) {
    width: 200px;
  }
  @media (prefers-color-scheme: dark) {
    color: var(--red-300);
  }
`;
const StyledLabel = styled.label`
  margin-top: 1rem;
`;

const MySelect = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <StyledLabel htmlFor={props.id || props.name}>{label}</StyledLabel>
      <StyledSelect {...field} {...props} />
      {meta.touched && meta.error ? (
        <StyledErrorMessage>{meta.error}</StyledErrorMessage>
      ) : null}
    </>
  );
};

// And now we can use these
const QuoteForm = () => {
  const [successIcon, setSuccessIcon] = useState(false);
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const handleGoogleSheetSubmit = async (resetForm) => {
    resetForm();
    setSuccessIcon(true);
  };
  const handleSubmit = async (values, resetForm) => {
    function getCurrentTimestamp() {
      const now = new Date();

      const month = now.getMonth() + 1; // Months are zero-based, so we add 1
      const day = now.getDate();
      const year = now.getFullYear();

      const hours = now.getHours();
      const minutes = now.getMinutes();
      const seconds = now.getSeconds();

      // Pad single digit values with leading zeros
      const formattedMonth = month < 10 ? `0${month}` : month;
      const formattedDay = day < 10 ? `0${day}` : day;
      const formattedHours = hours < 10 ? `0${hours}` : hours;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
      const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

      const formattedTimestamp = `${formattedMonth}/${formattedDay}/${year} ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
      return formattedTimestamp;
    }
    const currentTimestamp = getCurrentTimestamp();

    const newRow = { Timestamp: currentTimestamp, ...values };
    const savedData = await appendSpreadsheet(newRow);
    savedData
      ? handleGoogleSheetSubmit(resetForm)
      : alert("Somthing went wrong!, please try again");
  };

  useEffect(() => {
    const timeout = () =>
      setTimeout(() => {
        setSuccessIcon(false);
      }, 7000);
    successIcon && timeout();
  }, [successIcon]);

  return (
    <div className="service-quote-form">
      <h1>Get a Quote</h1>
      <Formik
        initialValues={{
          Name: "",
          "Phone Number": "",
          Email: "",
          "Product Type": "", // added for our select
          "Building Type": "",
          Description: "",
        }}
        validationSchema={Yup.object({
          Name: Yup.string()
            .max(50, "Must be 50 characters or less")
            .required("Required"),
          "Phone Number": Yup.string()
            .required("Required")
            .matches(phoneRegExp, "Phone number is not valid"),
          Email: Yup.string()
            .email("Invalid email addresss`")
            .required("Required"),
          "Product Type": Yup.string()
            .oneOf(
              ["Platinum", "Bonalux", "Otex", "Other", "Meyer", "Saclux"],
              "Invalid Product Type"
            )
            .required("Required"),
          "Building Type": Yup.string()
            .oneOf(
              [
                "Small Apartment",
                "3 Bed Room",
                "Old Building Bungalow",
                "New Building Bungalow",
                "Old Building Duplex",
                "New Building Duplex",
                "Other",
              ],
              "Invalid Building Type"
            )
            .required("Required"),
          Description: Yup.string()
            .min(10, "Must be at least 10 characters")
            .max(500, "Must be 500 characters or less"),
        })}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          await handleSubmit(values, resetForm);
          setSubmitting(false);
        }}
      >
        <Form>
          <MyTextInput label="Name" name="Name" type="text" placeholder="Doe" />
          <MyTextInput
            label="Phone Number"
            name="Phone Number"
            type="number"
            placeholder="+234 8131275908"
          />
          <MyTextInput
            label="Email Address"
            name="Email"
            type="email"
            placeholder="jane@formik.com"
          />
          <MySelect label="Product Type" name="Product Type">
            <option value="">Select a product type</option>
            <option value="Platinum">Platinum</option>
            <option value="Bonalux">Bonalux</option>
            <option value="Meyer">Meyer</option>
            <option value="Otex">Otex</option>
            <option value="Saclux">Saclux</option>
            <option value="Other">Other</option>
          </MySelect>
          <MySelect label="Building Type" name="Building Type">
            <option value="">Select a building type</option>
            <option value="Small Apartment">Small Apartment</option>
            <option value="3 Bed Room">3 Bed Room</option>
            <option value="Old Building Bungalow">Old Building Bungalow</option>
            <option value="New Building Bungalow">New Building Bungalow</option>
            <option value="Old Building Duplex">Old Building Duplex</option>
            <option value="New Building Duplex">New Building Duplex</option>
            <option value="Other">Other</option>
          </MySelect>
          <MyTextArea
            label="Description"
            name="Description"
            type="text"
            placeholder="Please give a brief description of your project"
          />
          <button className="quote-btn" type="submit">
            Submit
          </button>
          {successIcon && <GiCheckMark className="checkmark-icon" />}
        </Form>
      </Formik>
    </div>
  );
};

export default QuoteForm;
