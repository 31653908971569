import React from "react";
import { Link } from "react-router-dom";
import error from "../images/error.jpeg"

const Error = () => {
  return (
    <div className="error">
      <h3 className="error-title">Page Not Found</h3>
      <img loading="lazy" className="error-img" src={error} alt="error" />
      <p className="error-body">We can't seem to find the page you're looking for</p>
      <Link className="error-link" to="/">Back Home</Link>
    </div>
  );
};

export default Error;
