import React, { useState, useEffect } from "react";
import { Blurhash } from "react-blurhash";

const ImageComponent = ({ src, imgClass }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImageLoaded(true);
    };

    img.src = src;
  }, [src]);

  return (
    <>
      {!imageLoaded ? (
        <Blurhash
          hash="LEL:ffiKRvEX^l*^.6vy147MVs]g"
          width={300}
          height={300}
          resolutionX={32}
          resolutionY={32}
          punch={1}
        />
      ) : (
        <img src={src} alt={src} loading="lazy" className={imgClass} />
      )}
    </> 
  );
};

export default ImageComponent;
