import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectFade } from "swiper";
import { Autoplay, Pagination, Navigation } from "swiper";

// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import { Button } from "../button/Button";
import { CgList } from "react-icons/cg";
import "./SwiperHero.css";
import { Link } from "react-router-dom";
import { ContentfulDataHero } from "../../data/fetch-hero";
SwiperCore.use([Pagination, Autoplay, EffectFade, Navigation]);

const config = {
  grabCursor: true,
  spaceBetween: 30,
  centeredSlides: true,
  scrollbar: { draggable: true },
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
  },
  speed: 800,
  effect: "fade",
  pagination: {
    clickable: true,
  },
  allowTouchMove: true,
};

export default function SwiperHero() {

  const data = ContentfulDataHero("hero");
  return (
    <>
      <Swiper className="swiper-hero" {...config}>
        {data.map((slide) => {
          return (
            <SwiperSlide key={slide.id}>
              <CarouselSlide slide={slide} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
}

const CarouselSlide = ({ slide }) => {
  return (
    <div
      className="swiper-slider"
      key={slide.id}
      style={{ backgroundImage: `url(${slide.url})` }}
    >
      <div className="textwrap">
        <h2>{slide.title}</h2>
        <p>{slide.description}</p>
      </div>

      <div className="hero-btns">
        <Button
          type="button"
          buttonStyle="btn--outline"
          buttonSize="btn--large"
          onClick={() => console.log("hey")}
        >
          <Link to="/products/#products" className="hero-link">
            VIEW PRODUCTS
          </Link>
        </Button>
        <Button
          type="button"
          buttonStyle="btn--primary"
          buttonSize="btn--large"
          onClick={() => console.log("hey")}
        >
          <Link to="/services/#service-cards" className="hero-link">
            GET A QUOTE <CgList className="fa-icon" />
          </Link>
        </Button>
      </div>
    </div>
  );
};
