import React from "react";

import "./Service.css";
import wave from "../../images/accessories/wave2.svg";
import QuoteForm from "./Form";
import SEO from "../../components/seo/SEO";

import { AiOutlineSafetyCertificate } from "react-icons/ai";
import { IoSettingsOutline } from "react-icons/io5";
import { BsTruck } from "react-icons/bs";

const Service = () => {
  return (
    <div className="service-wrapper">
      <SEO
        title="Bonalux, Saclux,Kings, Eva, Meyer, Platinum Paints and Accessories - Best Shop to Buy Your Quality Paints in Warri"
        description="Leave everything to us; we deliver to your door step. Painting services include screeding and POP with kings paint, wallpaper, design, and stucco."
        name="Paint Shop in Warri - Carotec Nigeria Limited"
        type="Painting services and delivery"
        page="services"
      />
      <div className="service-hero">
        <img src={wave} alt="" />
        <p>
          We Offer a wide range of paints and coatings, including interior and
          exterior options, different finishes, and specialized products for
          specific applications. Provide customers with a variety of options to
          choose from, ensuring they find the perfect solution for their needs.
        </p>
      </div>
      <div id="service-cards">
        <div>
          Highest quality products from trusted brands.
          <small>
            {" "}
            <AiOutlineSafetyCertificate className="fa-icon-service" />
          </small>
        </div>
        <div>
          Reliable and timely delivery services to our customers.
          <small>
            {" "}
            <BsTruck className="fa-icon-service" />
          </small>
        </div>
        <div>
          Tailored solutions to meet specific requirements.
          <small>
            {" "}
            <IoSettingsOutline className="fa-icon-service" />
          </small>
        </div>
      </div>

      <div id="service-form">
        <QuoteForm />
      </div>
    </div>
  );
};

export default Service;
