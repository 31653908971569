import React from "react";
import office from "../../images/office.jpeg";
import "./About.css";

const About = () => {
  return (
    <section className="aboutWrapper">
      <h2 className="h2">About Us</h2>
      <div className="aboutContent">
        <div className="about-1">
          <img loading="lazy" src={office} alt="" />
        </div>
        <div className="about-2">
          <h4>Carotec Nigeria Limited</h4>
          <p>
            Registerd in 2018 with the Corporate Affairs Commission (CAC) of
            Nigeria, Carotec Nigeria Limited is a paint and building materials
            supply company with our coperate headquaters in Warri Delta state
          </p>

          <div className="vision-mission">
            <h3>Our Vision</h3>
            <p>
              Our vision is to be the leading provider of high-quality paints
              and building materials, offering innovative solutions and
              unmatched customer service. We strive to transform our customers'
              vision into reality, setting new industry standards through our
              commitment to excellence, sustainability, and building lasting
              relationships.
            </p>
            <h3>Our Mission</h3>
            <p>
              Our mission is to consistently deliver top-quality paints and
              building materials that exceed customer expectations. We strive to
              provide exceptional value, promote sustainability, and build
              strong partnerships within the industry, while continually seeking
              innovation and excellence.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
