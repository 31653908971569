import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "./Showcase.css";
import SwiperCore, { EffectCoverflow, Navigation } from "swiper";
import ImageComponent from "../ImageComponent";
import { ContentfulDataShowcase } from "../../data/fetch-showcase";

SwiperCore.use([ Navigation, EffectCoverflow]);

export default function App() {
  const data = ContentfulDataShowcase("platinumPaintings");
  return (
    <>
      {/* <h2 className="title-showcase h2">Showcase</h2> */}
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        loop={true}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        navigation={true}
        // pagination={{
        //   clickable: true,
        // }}
        className="swiper-products"
      >
        {data.map((slide) => {
          return (
            <SwiperSlide className="swiper-slide-products" key={slide.id}>
              <ImageComponent src={slide.url} imgClass="" />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
}
