import React from "react";
import "./Footer.css";
import logo from "../../images/logo.svg";

import { Link } from "react-router-dom";

import { FaPhone } from "react-icons/fa";
import {
  AiFillInstagram,
  AiFillLinkedin,
  AiFillTwitterCircle,
  AiFillFacebook,
  AiFillMail,
} from "react-icons/ai";

import { MdLocationOn } from "react-icons/md";

function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-link-wrapper">
        <div className="footer-link-items">
          <h2>Contact Us today</h2>

          <p>
            <small>
              <FaPhone className="contact-icon" />
            </small>{" "}
            <a href="tel:+2348131275908">+234 8131275908</a>
          </p>

          <p>
            <small>
              <AiFillMail className="contact-icon" />
            </small>{" "}
            <Link
              to="#"
              onClick={(e) => {
                window.location.href = "mailto:info@carotecgroup.com";
                e.preventDefault();
              }}
            >
              info@carotecgroup.com
            </Link>{" "}
          </p>
        </div>

        <div className="footer-link-items">
          <h2>Visit Us</h2>
          <aside>
            <small>
              <MdLocationOn className="contact-icon" />
            </small>
            <span>
              40 Effurun-Sapele Road, opposite Union Bank, Effurun Warri, Delta
              State
            </span>
          </aside>
        </div>
      </div>
      <section className="social-media">
        <div className="social-media-wrap">
          <div className="footer-logo">
            <img src={logo} alt="" srcSet="" />
          </div>
          <small className="website-rights">
            &copy; {new Date().getFullYear()} Copyright by Carotec Nigeria
            Limited. All Rights Reserved.
          </small>
          <div className="social-icons">
            <Link
              className="social-icon-link facebook"
              to="https://www.facebook.com/people/Carotec-Paints-And-Wallpapers/100066419715206/"
              target="_blank"
              aria-label="Facebook"
            >
              <AiFillFacebook />
            </Link>
            <Link
              className="social-icon-link instagram"
              to="https://www.instagram.com/carotec_ng/"
              target="_blank"
              aria-label="Instagram"
            >
              <AiFillInstagram />
            </Link>
            <Link
              className="social-icon-link linkedIn"
              to=""
              target="_blank"
              aria-label="LinkedIn"
            >
              <AiFillLinkedin />
            </Link>
            <Link
              className="social-icon-link twitter"
              to="https://twitter.com/CarotecNig"
              target="_blank"
              aria-label="Twitter"
            >
              <AiFillTwitterCircle />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
