import React, { useState, useCallback } from "react";
import { client } from "./client-contentful";

// function to clean contentful data
const cleanContentfulDataShowcase = (data) => {
  return data[0]?.fields?.paintings.map((item) => {
    const id = item.sys.id;
    const {
      file: { url },
      title,
    } = item.fields;
    let filtered = { id };

    if (url) {
      filtered = { ...filtered, url };
    }
    if (title) {
      filtered = { ...filtered, title };
    }
    return filtered;
  });
};

// function to be called in each contentful data component
export const ContentfulDataShowcase = (subject) => {
  const [contentful, setContentful] = useState([]);
  const getCarouselSlides = useCallback(async () => {
    try {
      const response = await client.getEntries({
        content_type: subject,
      });
      const heroImages = response?.items;
      const carouselfields = cleanContentfulDataShowcase(heroImages);

      setContentful(carouselfields);
    } catch (error) {
      console.log(error);
    }
  }, [subject]);

  React.useEffect(() => {
    getCarouselSlides();
  }, [getCarouselSlides]);

  return contentful;
};
