import { GoogleSpreadsheet } from "google-spreadsheet";

// appendSpreadsheet(newRow);
// https://docs.google.com/spreadsheets/d/1N24_FpCwA2nqga71zAXKNgb5T0D6SI5X26oMst26Fd8/edit?usp=sharing

// https://docs.google.com/spreadsheets/d/1N24_FpCwA2nqga71zAXKNgb5T0D6SI5X26oMst26Fd8/edit#gid=1971982129
// Config variables
const SPREADSHEET_ID = process.env.REACT_APP_SPREADSHEET_ID;
const SHEET_ID = process.env.REACT_APP_SHEET_ID;
const CLIENT_EMAIL = process.env.REACT_APP_GOOGLE_CLIENT_EMAIL;
const PRIVATE_KEY = process.env.REACT_APP_GOOGLE_SERVICE_PRIVATE_KEY.replace(
  /\\n/g,
  "\n"
);

const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

export const appendSpreadsheet = async (row) => {
  try {
    await doc.useServiceAccountAuth({
      client_email: CLIENT_EMAIL,
      private_key: PRIVATE_KEY,
    });
    // loads document properties and worksheets
    await doc.loadInfo();

    const sheet = doc.sheetsById[SHEET_ID];
    const response = await sheet.addRow(row);
    return response;
  } catch (e) {
    console.error("Error: ", e);
  }
};
