import React from "react";
import ImageComponent from "../../components/ImageComponent";
const Product = ({ currentItems }) => {
  return (
    <div className="products-page">
      {currentItems &&
        currentItems.map((product) => {
          console.log(product);
          const { id, title, url, desc } = product;

          return (
            <main key={id} className="menu-item">
              {/* <img loading="lazy" src={img} alt={title} className="photo" /> */}
              <ImageComponent imgClass="photo" src={url} />

              <div className="item-info">
                <h4>{title}</h4>

                <p className="item-text">{desc} </p>
              </div>
            </main>
          );
        })}
    </div>
  );
};

export default Product;
