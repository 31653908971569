import React, { useEffect, useState } from "react";
import "./Contact.css";

const ContactForm = ({ reference }) => {
  const [buttonContent, setButtonContent] = useState("Send Message");
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const [formValues, setFormValues] = useState({
    "entry.2005620554": "",
    "entry.1045781291": "",
    "entry.839337160": "",
  });

  const url = `https://docs.google.com/forms/u/0/d/e/1FAIpQLSc8aChbUpr1qQwJsuoWWBVHZIkhiYyBAN78EBml5Lub84DgFg/formResponse?entry.2005620554=${formValues["entry.2005620554"]}&entry.1045781291=${formValues["entry.1045781291"]}&entry.839337160=${formValues["entry.839337160"]}`;

  const handleChange = (e) => {
    setFormValues((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonContent("...loading");

    if (
      !formValues["entry.2005620554"] ||
      !formValues["entry.1045781291"] ||
      !formValues["entry.839337160"]
    ) {
      setErrorMessage(true);
      setButtonContent("Send Message");
      return;
    }
    try {
      const resp = await fetch(url, {
        method: "POST",
        mode: "no-cors",
      });

      if (resp.statusText === "") {
        setSuccessMessage(true);
        setFormValues({
          "entry.2005620554": "",
          "entry.1045781291": "",
          "entry.839337160": "",
        });
        setButtonContent("Send Message");
      }
    } catch (error) {
      setErrorMessage(true);
    }
  };

  useEffect(() => {
    const timeout = () =>
      setTimeout(() => {
        setSuccessMessage(false);
        setErrorMessage(false);
      }, 7000);
    (successMessage || errorMessage) && timeout();
    clearTimeout(timeout);
  }, [successMessage, errorMessage]);

  return (
    <section id="contact" className="contact" ref={reference}>
      <h2 className="h2">Contact Us</h2>
      <div className="underline"></div>
      <div className="contact-content">
        <div className="contact-ul">
          <p>
            Give us a Call From Monday to Saturday 8am – 6pm WAT{" "}
            <a href="tel:+2348131275908">+234 8131275908</a>
          </p>
          <p>
            {" "}
            Feel free to get in touch via email at:{" "}
            <small>info@carotecgroup.com</small>{" "}
          </p>
        </div>
        <div id="form">
          <h2 className="contact-enquiries">
            For enquiries, please fill out this form
          </h2>
          <form onSubmit={handleSubmit} className="form">
            <label className="contact-label form-label" htmlFor="email">
              Name
            </label>
            <input
              required={true}
              placeholder="John Doe"
              className="form-input"
              id="name"
              type="name"
              name="entry.2005620554"
              value={formValues["entry.2005620554"]}
              onChange={(e) => handleChange(e)}
            />
            <label className=" contact-label form-label" htmlFor="email">
              Email Address
            </label>
            <input
              required={true}
              placeholder="ade@gmail.com"
              className="form-input"
              id="email"
              type="email"
              name="entry.1045781291"
              value={formValues["entry.1045781291"]}
              onChange={(e) => handleChange(e)}
            />

            <div>
              <label className="contact-label form-label" htmlFor="message">
                Your Message
              </label>
              <textarea
                required={true}
                placeholder=" Type your request here"
                className="form-textarea"
                id="message"
                name="entry.839337160"
                value={formValues["entry.839337160"]}
                onChange={(e) => handleChange(e)}
              />

              {successMessage && (
                <div className="alert-success">
                  <h4>Thank you for getting in touch!</h4>
                  <span>We will reply shortly.</span>
                </div>
              )}
              {errorMessage && (
                <div className="alert-danger">
                  <p>Something went wrong! message not submitted</p>
                </div>
              )}
              <div className="btn-right">
                <button type="submit" className="contact-btn">
                  {buttonContent}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;

// const Button = styled.button`

// `;
