import React, { useEffect, useRef } from "react";
import Home from "./pages/Home";
import Products from "./pages/products/Products";
import Services from "./pages/services/Service";
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import Error from "./pages/Error";

import "./App.css";

import ScrollToTop from "react-scroll-to-top";
import { Routes, Route, useLocation } from "react-router-dom";
import WhatsApp from "./components/WhatsappWidget";
import { HelmetProvider } from "react-helmet-async";

const App = () => {
  const ref = useRef(null);

  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === "") {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  return (
    <HelmetProvider>
      <ScrollToTop
        smooth
        top={3000}
        color="white"
        width="20"
        height="20"
        style={{ backgroundColor: "#044e54", marginRight: "40px" }}
      />
      <Navbar onClick={handleClick} />
      <Routes>
        <Route index element={<Home reference={ref} />} />
        <Route path="services" element={<Services />} />
        <Route path="products" element={<Products />} />
        <Route path="*" element={<Error />} />
      </Routes>
      <Footer />
      <WhatsApp />
    </HelmetProvider>
  );
};

export default App;
