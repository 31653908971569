import React from "react";
import logo from "../images/logo.svg";
import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";

const WhatsApp = () => {
  return (
    <WhatsAppWidget
      className="whatsapp"
      phoneNo="2348131275908"
      position="right"
      widgetWidth="300px"
      widgetWidthMobile="260px"
      autoOpen={false}
      autoOpenTimer={15000}
      messageBox={true}
      iconSize="50"
      iconColor="white"
      iconBgColor="#25d366"
      headerIcon={logo}
      headerIconColor="pink"
      headerTxtColor="white"
      headerBgColor="tomato"
      headerTitle="Ifunanya"
      headerCaption="Online"
      bodyBgColor="#bbb"
      chatPersonName="Support"
      chatMessage={
        <>
          Hi there 👋 <br />
          <br /> How can I help you?
        </>
      }
      footerBgColor="#999"
      placeholder="Type a message.."
      btnBgColor="#044e54"
      btnTxt="Start Chat"
      btnTxtColor="#fff"
    />
  );
};

export default WhatsApp;
