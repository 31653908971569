import React from "react";
import SwiperHero from "../components/swiperHeroPage/SwiperHero";
import About from "../components/about/About";
import Cards from "../components/cards/Cards";
import ContactForm from "../components/contact/Contact";
import Showcase from "../components/showcase/Showcase";
import SEO from "../components/seo/SEO";

const Home = ({ reference }) => {
  return (
    <>
      <SEO
        title="Bonalux, Saclux, Meyer, Platinum Paints and accessories - Best Shop to buy your quality paints in Warri"
        description="Bonalux, Saclux, Meyer, Platinum,Paints and accessories. One stop shop for all your Painting needs, we are located in Warri Delta State, Nigeria. We sell Bonalux, Saclux, Platinum, Meyer and other quality products"
        name="Paint Shop in Warri - Carotec Nigeria Limited"
        type="Paint Shop (Depot) in warri Delta State Nigeria"
        page=""
      />
      <SwiperHero />
      <About />
      <Cards />
      <ContactForm reference={reference} />
      <Showcase />
    </>
  );
};

export default Home;
