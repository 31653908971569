import React from "react";
import "./Cards.css";
import CardItem from "./CardItem";

import platinum from "../../images/products/platinum.jpeg";
import meyer from "../../images/products/meyer.jpeg";
import saclux from "../../images/products/saclux-factory.jpeg";
import bonalux from "../../images/products/bonalux.png";
import accessories from "../../images/accessories/accessories.jpg";

function Cards() {
  return (
    <div className="cards">
      <h1 className="h2">Our Products</h1>
      <div className="cards__container">
        <div className="cards__wrapper">
          <ul className="cards__items">
            <CardItem
              src={platinum}
              text="Experience Exquisite Luxury and Style from our top quality design paints"
              label="Luxury & Durability"
              path="/services"
            />
            <CardItem
              src={meyer}
              text="Choose from a wide range of Premium coatings to meet every requirement"
              label="Premium"
              path="/services"
            />
          </ul>
          <ul className="cards__items">
            <CardItem
              src={saclux}
              text="Superior and Affordable options to give your home a face lift"
              label="Superior"
              path="/services"
            />
            <CardItem
              src={bonalux}
              text="Get good value for money with every choice you make"
              label="Elegant"
              path="/products"
            />
            <CardItem
              src={accessories}
              text="Accessories for DIY and professional usage"
              label="Versatile Choices"
              path="/sign-up"
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
