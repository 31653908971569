import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import Product from "./Product";

import SEO from "../../components/seo/SEO";
import "./Products.css";
import { ContentfulDataProducts } from "../../data/fetch-products";

const Products = () => {
  const data = ContentfulDataProducts("products");

  const allCategories = React.useMemo(
    () => ["all", ...new Set(data.map((item) => item.category))],
    [data]
  );

  const [products, setProducts] = useState(data);

  const [categories, setCategories] = useState(allCategories);
  console.log(categories);
  const [activeId, setActiveId] = useState(-1);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    setCategories(allCategories);
    setProducts(data);
  }, [data, allCategories]);

  const filterProducts = (category, index) => {
    if (category === "all") {
      setProducts(data);
      setActiveId(index);
      return;
    }
    const newItems = data.filter((item) => item.category === category);
    setProducts(newItems);
    setActiveId(index);
  };

  const itemsPerPage = 10;
  const endOffset = itemOffset + 10;
  const currentItems = products.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(products.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % products.length;
    setItemOffset(newOffset);
  };

  return (
    <div id="products">
      <SEO
        title="Bonalux, Saclux, Meyer, Platinum Paints and accessories - Best Shop to buy your quality paints in Warri - Best Shop to buy your quality paints in Warri."
        description="We sell Bonalux, Saclux, Meyer, Platinum, Kings, Eva Paints and accessories."
        name="Paint Shop in Warri - Carotec Nigeria Limited"
        type="Paint Shop (Depot) in warri Delta State Nigeria"
        page="products"
      />

      <div className="title">
        <h3 className="products-page-title">Products</h3>
        <div className="titl-underline"></div>
      </div>

      <div className="btn-container">
        {categories.map((category, index) => {
          return (
            <button
              type="button"
              key={index}
              onClick={() => {
                filterProducts(category, index);
              }}
              className={`${
                activeId === index ? "border-btm filter-btn" : "filter-btn"
              }`}
            >
              {category}
            </button>
          );
        })}
      </div>

      <Product currentItems={currentItems} />
      {itemsPerPage >= 10 && (
        <ReactPaginate
          breakLabel="..."
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          renderOnZeroPageCount={null}
          previousLabel={"← Previous"}
          nextLabel={"Next →"}
          pageCount={pageCount}
          containerClassName={"pagination"}
          previousLinkClassName={"pagination__link"}
          nextLinkClassName={"pagination__link"}
          disabledClassName={"pagination__link--disabled"}
          activeClassName={"pagination__link--active"}
        />
      )}
    </div>
  );
};

export default Products;
