import React, { useState, useCallback } from "react";
import { client } from "./client-contentful";

// function to clean contentful data
const cleanContentfulDataHero = (data) => {
  console.log(data);
  return data?.map((item) => {
    const id = item.sys.id;
    const {
      image: { fields: { file: { url } } },
      title,
      description,
    } = item.fields
    let filtered = { id };

    if (url) {
      filtered = { ...filtered, url };
    }
    if (title) {
      filtered = { ...filtered, title };
    }
    if (description) {
      filtered = { ...filtered, description };
    }

    return filtered;
  });
};


// function to be called in each contentful data component
export const ContentfulDataHero = (subject) => {
  const [contentful, setContentful] = useState([]);
  const getCarouselSlides = useCallback(async () => {
    try {
      const response = await client.getEntries({
        content_type: subject,
      });
      const heroImages = response?.items;
      const carouselfields = cleanContentfulDataHero(heroImages);

      setContentful(carouselfields);
    } catch (error) {
      console.log(error);
    }
  }, [subject]);

  React.useEffect(() => {
    getCarouselSlides();
  }, [getCarouselSlides]);

  return contentful;
};
